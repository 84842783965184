/* You can add global styles to this file, and also import other style files */
@tailwind base;
@tailwind components;
@tailwind utilities;
$font-family_1: "inter";
$font-family_2: "jost";

/* You can add global styles to this file, and also import other style files */
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-Black.ttf);
	font-weight: 900;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-ExtraBold.ttf);
	font-weight: 800;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-Bold.ttf);
	font-weight: 700;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-SemiBold.ttf);
	font-weight: 600;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-Medium.ttf);
	font-weight: 500;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-Medium.ttf);
	font-weight: 500;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-Regular.ttf);
	font-weight: 400;
}
@font-face {
	font-family: "inter";
	src: url(/assets/fonts/Inter-Light.ttf);
	font-weight: 300;
}
@font-face {
	font-family: "jost";
	src: url(/assets/fonts/Jost-Light.ttf);
	font-weight: 300;
}
@font-face {
	font-family: "jost";
	src: url(/assets/fonts/Jost-Regular.ttf);
	font-weight: 400;
}
@font-face {
	font-family: "jost";
	src: url(/assets/fonts/Jost-Medium.ttf);
	font-weight: 500;
}
@font-face {
	font-family: "jost";
	src: url(/assets/fonts/Jost-SemiBold.ttf);
	font-weight: 600;
}
@font-face {
	font-family: "jost";
	src: url(/assets/fonts/Jost-Bold.ttf);
	font-weight: 600;
}
@font-face {
	font-family: "jost";
	src: url(/assets/fonts/Jost-Black.ttf);
	font-weight: 900;
}
body {
	font-family: $font-family_1;
	font-weight: 400;
	background: #F8FCF9;
}
p {
	font-family: $font-family_1;
	font-weight: 400;
}
span {
	font-family: $font-family_1;
	font-weight: 400;
}
li {
	font-family: $font-family_1;
	font-weight: 400;
}
ul {
	font-family: $font-family_1;
	font-weight: 400;
}
div {
	font-family: $font-family_1;
	font-weight: 400;
}
a {
	font-family: $font-family_1;
	font-weight: 400;
}
h1 {
	font-family: $font-family_1;
}
h2 {
	font-family: $font-family_1;
}
h3 {
	font-family: $font-family_1;
}
h4 {
	font-family: $font-family_1;
}
h5 {
	font-family: $font-family_1;
}
h6 {
	font-family: $font-family_1;
}
.ngx-slider.two-side-range {
	margin-top: 20px;
	.ngx-slider-pointer {
		background: #317756;
		width: 24px;
		height: 24px;
		top: -9px;
		&:after {
			display: none;
		}
	}
	.ngx-slider-selection {
		background: #317756 !important;
	}
	.ngx-slider-bar {
		background: #daf2e0;
		height: 8px;
		border-radius: 4px;
	}
	.ngx-slider-bar-wrapper {
		visibility: visible !important;
	}
}
body{
  transition: all 0.3s;
}
body.bottom-sheet-open {
	overflow: hidden;
  touch-action: none;
	&::before {
		content: '';
		background: #0B0E0D1A;
		backdrop-filter: blur(10px);
		width: 100%;
		height: 100%;
		position: fixed;
		top: 0;
		z-index: 99;
    transition: all 0.3s;
	}
}


body.popup-menu-open {
	&:before {
		content: '';
		position: fixed;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.2);
    z-index: 1;
	}
	// overflow: hidden;
}


.gantt-chart-container {
  overflow: auto;
}


.highcharts-container  {
  width: 100% !important;
  height: auto !important;
}

svg.highcharts-root {
  width: 100% !important;
  height: auto !important;
}
.highcharts-axis-line {
  display: none !important;
}

.title-chart {
  padding: 0 10px;
}



.owl-slider {
	.owl-carousel {
		.owl-stage {
			display: flex;
			padding: 0 0 10px;
		}
	}
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.ngx-slider-span.ngx-slider-bubble.ngx-slider-combined{
	visibility: hidden !important;
}

:root {
    --face-display: none; /* block or none */

    /* Layout */
    --nav-height: 120px;
    --popup-margin: 15px;
    --nav-color: #447658;
    
    /* Colors */
    --popup-bg: #F7F7F7;

    /* Face Features Visibility */
    --show-eyes: 1;
    --show-mouth: 1;
    --show-teeth: 1;
    
    /* Animation Timings */
    --jump-duration: 1.2s;
    --popup-duration: 0.8s;
    --popup-delay: 0.1s;
    
    /* Animation Curves */
    --jump-curve: cubic-bezier(0.16, 1, 0.3, 1);
    --popup-curve: var(--jump-curve);

    /* Jump Animation */
    --jump-height-offset: 50px; /* Additional height above popup */
    --jump-height: var(--calculated-jump-height, 50vh);
    --jump-scale: 1.1;
    --jump-rotation: 20deg;
    --jump-prepare-y: 15%;
    --jump-prepare-scale-x: 0.92;
    --jump-prepare-scale-y: 0.85;
    --jump-prepare-rotation: 15deg;

    /* Dizzy Animation */
    --dizzy-duration: 1s;
    --dizzy-spins: 4;
    --dizzy-timing: ease-in-out;
}

.logo-container #left-eye-group .eye,
.logo-container #right-eye-group .eye {
    animation: blink 4s ease-in-out infinite;
    transform-origin: center;
    transform-box: fill-box;
}

.logo-container #left-eye-group,
.logo-container #right-eye-group {
    animation: rollEyes 8s ease-in-out infinite;
    transform-origin: center;
    transform-box: fill-box;
}

.logo-container #mouth {
    animation: chew 3s ease-in-out infinite;
    transform-origin: center;
    transform-box: fill-box;
}

/* logo */

.logo-container {
    position: absolute;
    width: 66%;
    height: 66%;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    transform-style: preserve-3d;
    perspective: 300px;
    outline: none;
}

.logo-container > div {
    position: absolute;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 0.15s cubic-bezier(0.4, 0, 0.2, 1);
}

.logo-container > div.prepare-jump {
    transform: translateY(var(--jump-prepare-y)) 
             scale(var(--jump-prepare-scale-x), var(--jump-prepare-scale-y)) 
             rotateX(var(--jump-prepare-rotation));
}

.logo-container > div.jumping {
    animation: carrotJump var(--jump-duration) var(--jump-curve);
    transform-origin: center bottom;
}

.logo-container #left-eye-group,
.logo-container #right-eye-group {
    display: var(--face-display);
}

.logo-container #mouth {
    display: var(--face-display);
}

.logo-container #left-tooth,
.logo-container #right-tooth {
    display: var(--face-display);
}

@keyframes blink {
    0%, 45%, 55%, 100% {
        transform: scaleY(1);
    }
    50% {
        transform: scaleY(0.1);
    }
}

@keyframes chew {
    0%, 15%, 100% { transform: translate(0, -15px); }
    5% { transform: translate(0, -35px); }
    10% { transform: translate(0, -5px); }
    20% { transform: translate(0, -35px); }
    25% { transform: translate(0, -5px); }
    30% { transform: translate(0, -35px); }
    35% { transform: translate(0, -15px); }
}

@keyframes rollEyes {
    0%, 85%, 100% { transform: rotate(0deg); }
    25% { transform: rotate(360deg); }
    30% { transform: rotate(340deg); }
    35% { transform: rotate(360deg); }
}

@keyframes dizzyEyes {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(calc(360deg * var(--dizzy-spins) / 4)); }
    50% { transform: rotate(calc(360deg * var(--dizzy-spins) / 2)); }
    75% { transform: rotate(calc(360deg * var(--dizzy-spins) * 3 / 4)); }
    100% { transform: rotate(calc(360deg * var(--dizzy-spins))); }
}

@keyframes carrotJump {
    0% {
        transform: translateY(var(--jump-prepare-y)) 
                 scale(var(--jump-prepare-scale-x), var(--jump-prepare-scale-y)) 
                 rotateX(var(--jump-prepare-rotation));
    }
    30% {
        transform: translateY(calc(var(--jump-height) * -1)) 
                 scale(var(--jump-scale), var(--jump-scale)) 
                 rotateX(calc(var(--jump-rotation) * -1));
    }
    60% {
        transform: translateY(15%) 
                 scale(0.92, 0.92) 
                 rotateX(10deg);
    }
    75% {
        transform: translateY(-10%) 
                 scale(1.02, 1.02) 
                 rotateX(-5deg);
    }
    85% {
        transform: translateY(5%) 
                 scale(0.98, 0.98) 
                 rotateX(3deg);
    }
    92% {
        transform: translateY(-2%) 
                 scale(1.01, 1.01) 
                 rotateX(-1deg);
    }
    100% {
        transform: translateY(0) 
                 scale(1, 1) 
                 rotateX(0deg);
    }
}

.logo-container .dizzy {
    animation: dizzyEyes var(--dizzy-duration) var(--dizzy-timing) !important;
}

.logo-container > div.prepare-jump svg {
    animation-play-state: paused;
}

.logo-container svg {
    width: 100%;
    height: 100%;
    animation: carrotFloat 8s ease-in-out infinite;
    transform-style: preserve-3d;
}

@keyframes carrotFloat {
    0%, 100% {
        transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) translate3d(0, 0, -5px) scale(0.96);
    }
    25% {
        transform: rotateX(5deg) rotateY(3deg) rotateZ(2deg) translate3d(1px, -2px, 5px) scale(1);
    }
    50% {
        transform: rotateX(-3deg) rotateY(-2deg) rotateZ(-1deg) translate3d(0, 1px, -8px) scale(0.97);
    }
    75% {
        transform: rotateX(4deg) rotateY(2deg) rotateZ(1deg) translate3d(-1px, -1px, 3px) scale(0.99);
    }
}

.logo-container #left-leaf-mask {
    animation: carrotLeafLeft 3s ease-in-out infinite;
    transform-origin: bottom center;
    transform-box: fill-box;
}

.logo-container #center-leaf-mask {
    animation: carrotLeafCenter 3s ease-in-out infinite;
    transform-origin: bottom center;
    transform-box: fill-box;
    animation-delay: 0.2s;
}

.logo-container #right-leaf-mask {
    animation: carrotLeafRight 3s ease-in-out infinite;
    transform-origin: bottom center;
    transform-box: fill-box;
    animation-delay: 0.4s;
}

@keyframes carrotLeafLeft {
    0%, 100% { transform: rotate(0deg) scale(1); }
    25% { transform: rotate(-12deg) scale(1.02); }
    50% { transform: rotate(4deg) scale(0.98); }
    75% { transform: rotate(-8deg) scale(1.01); }
}

@keyframes carrotLeafCenter {
    0%, 100% { transform: rotate(0deg) scale(1); }
    25% { transform: rotate(-8deg) scale(1.02); }
    50% { transform: rotate(8deg) scale(0.98); }
    75% { transform: rotate(-5deg) scale(1.01); }
}

@keyframes carrotLeafRight {
    0%, 100% { transform: rotate(0deg) scale(1); }
    25% { transform: rotate(8deg) scale(1.02); }
    50% { transform: rotate(-4deg) scale(0.98); }
    75% { transform: rotate(12deg) scale(1.01); }
}
